import { Decimal } from 'decimal.js'
import { decimalsObj } from '~/constants/general'

const getDecimals = (unit: string, customDecimals?: number) => {
  if (customDecimals) return customDecimals

  const decimals = unit === '' ? 1 : decimalsObj[unit.trim()]

  if (decimals === undefined) return undefined

  return decimals
}

const toDecimalPlaces = (
  value: number,
  unit: string,
  fixed = false,
  withThousandsSeparator = true,
  customDecimals?: number,
) => {
  if (!value) return 0

  const decimals = getDecimals(unit, customDecimals)

  if (decimals === undefined) return value

  if (!fixed) return new Decimal(value).toDP(decimals).toNumber()

  const newValue = new Decimal(value).toFixed(decimals)

  return Number(newValue).toLocaleString('en-US', {
    maximumFractionDigits: decimals,
    useGrouping: withThousandsSeparator,
  })
}

export default toDecimalPlaces
